$formFontSize: 16pt;

.questions {
    
    font-size: $formFontSize;
    color: $primaryDark;

    input[type=number],
    input[type=date],
    input[type=text] {
        font-size: $formFontSize - 2;
        padding: 25px 10px;
    }    
    
    select {
        font-size: $formFontSize - 4;
        // padding: 30px 10px;
    }
    
    section {
        margin-bottom: 80px;
        h3 {
            font-size: $formFontSize + 8;
            color: $primary;
            margin-bottom: 20px;
        }
    }
    
    .form-group {
        @extend .margin-bottom-40;
    }

    .modal {
        .form-group {
            @extend .margin-bottom-10;
        }
    }   
    
    .help-block {
        font-size: 12pt;
    } 
}

.checkbox.vertical {
    label {
        display: block;
    }
}

#addTeamMember {
    textarea {
        height: 200px;
    }
}

.popover {
    max-width: 300px;
}

textarea {
    min-height: 200px;
}

.limited-textarea {
    position: relative;
    span {
        color: #999;
        font-size: 10pt;
        position: absolute;
        bottom: 5;
        right: 10;
    }
}