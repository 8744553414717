$types: padding margin;
$list: top left bottom right;

@each $type in $types {
    @each $direction in $list {
        @for $i from 0 through 40 {
            $value: $i * 5;
            .#{$type}-#{$direction}-#{$value} {
                #{$type}-#{$direction}: #{$value}px;
            }
        }
    }
}