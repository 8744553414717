.header {
    .container {
        img {
            width: 150px;
        }
    }
}

.landing .container {
    width: 100%;
    padding: 0;
    margin: 0;
}

p {
    font-size: 16;
}

h3 {
    font-weight: bold;
}

.hero-container {
    height: 50%;
}

.hero-image {
    background-image: url("/images/landing_dark.jpg");
    background-size: cover;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    .hero-form {
        text-align: center;
        .hero-input {
            border: 1px solid #cc6734;
            background-color: beige;
            padding: 20px;
            margin: 10px;
            font-size: 16px;
            text-align: center;
        }
        .hero-button {
            background-color: #cc6734;
            border: 1px solid #cc6734;
            padding: 10px;
            font-size: 16px;
        }
    }
}

.hero-text {
    color: white;
    font-size: 32px;
    text-align: center;
    padding-left: 10%;
    padding-right: 10%;
}

.body-container {
    .intro {
        margin: 20px;
        font-weight: 'bold';
    }
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: center;
    align-content: center;
    margin: 40px 10% 30px 10%;
}

.body-input {
    border: 1px solid #cc6734;
    background-color: beige;
    padding: 20px;
    margin: 5px 10px;
    font-size: 16px;
    width: 100%;
}

.body-radio {
    padding: 20px;
    margin: 5px 10px;
    font-size: 16px;
    width: 100%;
}

.body-button {
    margin: 5px 10px;
    background-color: #cc6734;
    border: 1px solid #cc6734;
    padding: 10px;
    font-size: 16px;
    width: 120px;
    text-align: center;
}

.info-panels {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    align-content: center;
    margin-top: 40px;
    .screenshot {
        order: 2;
        width: 100%;
        padding: 20px;
        .sample-chat {
            margin: 0 auto;
            width: 100%;
        }
    }
    .blurb {
        order: 1;
        width: 100%;
        padding: 0 5% 0 5%;
    }
    .app-info {
        order: 3;
        width: 100%;
        padding: 0 5% 0 5%;
    }
    .app-invite {
        order: 4;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 20px;
        .mobile-overlay {
            margin: 0 auto;
            width: 30%;
        }
        .group {
            padding: 20px;
            width: 70%;
            margin: 0 auto;
            .app-store {
                width: 40%;
                margin: 0 5px;
            }
        }
    }
}

.footer {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: center;
    background-color: rgb(52,52,52);
    padding-top: 40px;
    padding-bottom: 50px;
    h3 {
        color: #cc6734;
    }
    p {
        color: white;
        margin: 5px 10px 0 0;
    }
    a {
        color: white;
        display: block;
        margin: 5px 10px 0 0;
    }
    .footer-section-one {
        width: 100%;
        text-align: center;
    }
    .footer-section-two {
        width: 100%;
        text-align: center;
    }
    .footer-section-three {
        width: 100%;
        text-align: center;
    }
}

@media (min-width: 800px) {
    .header {
        padding-left: 50px;
        .container {
            img {
                width: 300px;
                margin-left: 10%;
            }
        }
    }

    .hero-image {
        .hero-form {
            .hero-input {
                padding: 24px;
                margin: 10px;
                font-size: 20px;
            }
            .hero-button {
                padding: 10px;
                margin: 10px;
                font-size: 20px;
            }
        }
    }

    p {
        font-size: 21;
    }

    .hero-text {
        font-size: 50px;
    }

    .body-container {
        .intro {
        }
    }

    .info-panels {
        .screenshot {
            order: 1;
            width: 50%;
            padding: 20px;
            .sample-chat {
                margin: 0 auto;
                width: 100%;
            }
        }
        .blurb {
            order: 2;
            width: 50%;
            padding: 20px;
        }
        .app-info {
            order: 3;
            width: 50%;
            padding: 0 5% 0 5%;
        }
        .app-invite {
            order: 4;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-around;
            align-items: center;
            width: 50%;
            padding: 20px;
            .mobile-overlay {
                margin: 0 auto;
                width: 30%;
            }
            .group {
                padding: 20px;
                width: 70%;
            }
        }
    }

    .footer {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-end;
        align-items: flex-start;
        background-color: rgb(52,52,52);
        h3 {
            color: #cc6734;
        }
        p {
            color: white;
            margin: 5px 10px 0 0;
        }
        a {
            color: white;
            display: block;
            margin: 5px 10px 0 0;
        }
        height: 250px;
        .footer-section-one {
            width: 31%;
            text-align: left;
        }
        .footer-section-two {
            width: 31%;
            text-align: left;
        }
        .footer-section-three {
            width: 31%;
            text-align: left;
        }
    }
}
