.padding-top-0 {
  padding-top: 0px;
}

.padding-top-5 {
  padding-top: 5px;
}

.padding-top-10 {
  padding-top: 10px;
}

.padding-top-15 {
  padding-top: 15px;
}

.padding-top-20 {
  padding-top: 20px;
}

.padding-top-25 {
  padding-top: 25px;
}

.padding-top-30 {
  padding-top: 30px;
}

.padding-top-35 {
  padding-top: 35px;
}

.padding-top-40 {
  padding-top: 40px;
}

.padding-top-45 {
  padding-top: 45px;
}

.padding-top-50 {
  padding-top: 50px;
}

.padding-top-55 {
  padding-top: 55px;
}

.padding-top-60 {
  padding-top: 60px;
}

.padding-top-65 {
  padding-top: 65px;
}

.padding-top-70 {
  padding-top: 70px;
}

.padding-top-75 {
  padding-top: 75px;
}

.padding-top-80 {
  padding-top: 80px;
}

.padding-top-85 {
  padding-top: 85px;
}

.padding-top-90 {
  padding-top: 90px;
}

.padding-top-95 {
  padding-top: 95px;
}

.padding-top-100 {
  padding-top: 100px;
}

.padding-top-105 {
  padding-top: 105px;
}

.padding-top-110 {
  padding-top: 110px;
}

.padding-top-115 {
  padding-top: 115px;
}

.padding-top-120 {
  padding-top: 120px;
}

.padding-top-125 {
  padding-top: 125px;
}

.padding-top-130 {
  padding-top: 130px;
}

.padding-top-135 {
  padding-top: 135px;
}

.padding-top-140 {
  padding-top: 140px;
}

.padding-top-145 {
  padding-top: 145px;
}

.padding-top-150 {
  padding-top: 150px;
}

.padding-top-155 {
  padding-top: 155px;
}

.padding-top-160 {
  padding-top: 160px;
}

.padding-top-165 {
  padding-top: 165px;
}

.padding-top-170 {
  padding-top: 170px;
}

.padding-top-175 {
  padding-top: 175px;
}

.padding-top-180 {
  padding-top: 180px;
}

.padding-top-185 {
  padding-top: 185px;
}

.padding-top-190 {
  padding-top: 190px;
}

.padding-top-195 {
  padding-top: 195px;
}

.padding-top-200 {
  padding-top: 200px;
}

.padding-left-0 {
  padding-left: 0px;
}

.padding-left-5 {
  padding-left: 5px;
}

.padding-left-10 {
  padding-left: 10px;
}

.padding-left-15 {
  padding-left: 15px;
}

.padding-left-20 {
  padding-left: 20px;
}

.padding-left-25 {
  padding-left: 25px;
}

.padding-left-30 {
  padding-left: 30px;
}

.padding-left-35 {
  padding-left: 35px;
}

.padding-left-40 {
  padding-left: 40px;
}

.padding-left-45 {
  padding-left: 45px;
}

.padding-left-50 {
  padding-left: 50px;
}

.padding-left-55 {
  padding-left: 55px;
}

.padding-left-60 {
  padding-left: 60px;
}

.padding-left-65 {
  padding-left: 65px;
}

.padding-left-70 {
  padding-left: 70px;
}

.padding-left-75 {
  padding-left: 75px;
}

.padding-left-80 {
  padding-left: 80px;
}

.padding-left-85 {
  padding-left: 85px;
}

.padding-left-90 {
  padding-left: 90px;
}

.padding-left-95 {
  padding-left: 95px;
}

.padding-left-100 {
  padding-left: 100px;
}

.padding-left-105 {
  padding-left: 105px;
}

.padding-left-110 {
  padding-left: 110px;
}

.padding-left-115 {
  padding-left: 115px;
}

.padding-left-120 {
  padding-left: 120px;
}

.padding-left-125 {
  padding-left: 125px;
}

.padding-left-130 {
  padding-left: 130px;
}

.padding-left-135 {
  padding-left: 135px;
}

.padding-left-140 {
  padding-left: 140px;
}

.padding-left-145 {
  padding-left: 145px;
}

.padding-left-150 {
  padding-left: 150px;
}

.padding-left-155 {
  padding-left: 155px;
}

.padding-left-160 {
  padding-left: 160px;
}

.padding-left-165 {
  padding-left: 165px;
}

.padding-left-170 {
  padding-left: 170px;
}

.padding-left-175 {
  padding-left: 175px;
}

.padding-left-180 {
  padding-left: 180px;
}

.padding-left-185 {
  padding-left: 185px;
}

.padding-left-190 {
  padding-left: 190px;
}

.padding-left-195 {
  padding-left: 195px;
}

.padding-left-200 {
  padding-left: 200px;
}

.padding-bottom-0 {
  padding-bottom: 0px;
}

.padding-bottom-5 {
  padding-bottom: 5px;
}

.padding-bottom-10 {
  padding-bottom: 10px;
}

.padding-bottom-15 {
  padding-bottom: 15px;
}

.padding-bottom-20 {
  padding-bottom: 20px;
}

.padding-bottom-25 {
  padding-bottom: 25px;
}

.padding-bottom-30 {
  padding-bottom: 30px;
}

.padding-bottom-35 {
  padding-bottom: 35px;
}

.padding-bottom-40 {
  padding-bottom: 40px;
}

.padding-bottom-45 {
  padding-bottom: 45px;
}

.padding-bottom-50 {
  padding-bottom: 50px;
}

.padding-bottom-55 {
  padding-bottom: 55px;
}

.padding-bottom-60 {
  padding-bottom: 60px;
}

.padding-bottom-65 {
  padding-bottom: 65px;
}

.padding-bottom-70 {
  padding-bottom: 70px;
}

.padding-bottom-75 {
  padding-bottom: 75px;
}

.padding-bottom-80 {
  padding-bottom: 80px;
}

.padding-bottom-85 {
  padding-bottom: 85px;
}

.padding-bottom-90 {
  padding-bottom: 90px;
}

.padding-bottom-95 {
  padding-bottom: 95px;
}

.padding-bottom-100 {
  padding-bottom: 100px;
}

.padding-bottom-105 {
  padding-bottom: 105px;
}

.padding-bottom-110 {
  padding-bottom: 110px;
}

.padding-bottom-115 {
  padding-bottom: 115px;
}

.padding-bottom-120 {
  padding-bottom: 120px;
}

.padding-bottom-125 {
  padding-bottom: 125px;
}

.padding-bottom-130 {
  padding-bottom: 130px;
}

.padding-bottom-135 {
  padding-bottom: 135px;
}

.padding-bottom-140 {
  padding-bottom: 140px;
}

.padding-bottom-145 {
  padding-bottom: 145px;
}

.padding-bottom-150 {
  padding-bottom: 150px;
}

.padding-bottom-155 {
  padding-bottom: 155px;
}

.padding-bottom-160 {
  padding-bottom: 160px;
}

.padding-bottom-165 {
  padding-bottom: 165px;
}

.padding-bottom-170 {
  padding-bottom: 170px;
}

.padding-bottom-175 {
  padding-bottom: 175px;
}

.padding-bottom-180 {
  padding-bottom: 180px;
}

.padding-bottom-185 {
  padding-bottom: 185px;
}

.padding-bottom-190 {
  padding-bottom: 190px;
}

.padding-bottom-195 {
  padding-bottom: 195px;
}

.padding-bottom-200 {
  padding-bottom: 200px;
}

.padding-right-0 {
  padding-right: 0px;
}

.padding-right-5 {
  padding-right: 5px;
}

.padding-right-10 {
  padding-right: 10px;
}

.padding-right-15 {
  padding-right: 15px;
}

.padding-right-20 {
  padding-right: 20px;
}

.padding-right-25 {
  padding-right: 25px;
}

.padding-right-30 {
  padding-right: 30px;
}

.padding-right-35 {
  padding-right: 35px;
}

.padding-right-40 {
  padding-right: 40px;
}

.padding-right-45 {
  padding-right: 45px;
}

.padding-right-50 {
  padding-right: 50px;
}

.padding-right-55 {
  padding-right: 55px;
}

.padding-right-60 {
  padding-right: 60px;
}

.padding-right-65 {
  padding-right: 65px;
}

.padding-right-70 {
  padding-right: 70px;
}

.padding-right-75 {
  padding-right: 75px;
}

.padding-right-80 {
  padding-right: 80px;
}

.padding-right-85 {
  padding-right: 85px;
}

.padding-right-90 {
  padding-right: 90px;
}

.padding-right-95 {
  padding-right: 95px;
}

.padding-right-100 {
  padding-right: 100px;
}

.padding-right-105 {
  padding-right: 105px;
}

.padding-right-110 {
  padding-right: 110px;
}

.padding-right-115 {
  padding-right: 115px;
}

.padding-right-120 {
  padding-right: 120px;
}

.padding-right-125 {
  padding-right: 125px;
}

.padding-right-130 {
  padding-right: 130px;
}

.padding-right-135 {
  padding-right: 135px;
}

.padding-right-140 {
  padding-right: 140px;
}

.padding-right-145 {
  padding-right: 145px;
}

.padding-right-150 {
  padding-right: 150px;
}

.padding-right-155 {
  padding-right: 155px;
}

.padding-right-160 {
  padding-right: 160px;
}

.padding-right-165 {
  padding-right: 165px;
}

.padding-right-170 {
  padding-right: 170px;
}

.padding-right-175 {
  padding-right: 175px;
}

.padding-right-180 {
  padding-right: 180px;
}

.padding-right-185 {
  padding-right: 185px;
}

.padding-right-190 {
  padding-right: 190px;
}

.padding-right-195 {
  padding-right: 195px;
}

.padding-right-200 {
  padding-right: 200px;
}

.margin-top-0 {
  margin-top: 0px;
}

.margin-top-5 {
  margin-top: 5px;
}

.margin-top-10 {
  margin-top: 10px;
}

.margin-top-15 {
  margin-top: 15px;
}

.margin-top-20 {
  margin-top: 20px;
}

.margin-top-25 {
  margin-top: 25px;
}

.margin-top-30 {
  margin-top: 30px;
}

.margin-top-35 {
  margin-top: 35px;
}

.margin-top-40 {
  margin-top: 40px;
}

.margin-top-45 {
  margin-top: 45px;
}

.margin-top-50 {
  margin-top: 50px;
}

.margin-top-55 {
  margin-top: 55px;
}

.margin-top-60 {
  margin-top: 60px;
}

.margin-top-65 {
  margin-top: 65px;
}

.margin-top-70 {
  margin-top: 70px;
}

.margin-top-75 {
  margin-top: 75px;
}

.margin-top-80 {
  margin-top: 80px;
}

.margin-top-85 {
  margin-top: 85px;
}

.margin-top-90 {
  margin-top: 90px;
}

.margin-top-95 {
  margin-top: 95px;
}

.margin-top-100 {
  margin-top: 100px;
}

.margin-top-105 {
  margin-top: 105px;
}

.margin-top-110 {
  margin-top: 110px;
}

.margin-top-115 {
  margin-top: 115px;
}

.margin-top-120 {
  margin-top: 120px;
}

.margin-top-125 {
  margin-top: 125px;
}

.margin-top-130 {
  margin-top: 130px;
}

.margin-top-135 {
  margin-top: 135px;
}

.margin-top-140 {
  margin-top: 140px;
}

.margin-top-145 {
  margin-top: 145px;
}

.margin-top-150 {
  margin-top: 150px;
}

.margin-top-155 {
  margin-top: 155px;
}

.margin-top-160 {
  margin-top: 160px;
}

.margin-top-165 {
  margin-top: 165px;
}

.margin-top-170 {
  margin-top: 170px;
}

.margin-top-175 {
  margin-top: 175px;
}

.margin-top-180 {
  margin-top: 180px;
}

.margin-top-185 {
  margin-top: 185px;
}

.margin-top-190 {
  margin-top: 190px;
}

.margin-top-195 {
  margin-top: 195px;
}

.margin-top-200 {
  margin-top: 200px;
}

.margin-left-0 {
  margin-left: 0px;
}

.margin-left-5 {
  margin-left: 5px;
}

.margin-left-10 {
  margin-left: 10px;
}

.margin-left-15 {
  margin-left: 15px;
}

.margin-left-20 {
  margin-left: 20px;
}

.margin-left-25 {
  margin-left: 25px;
}

.margin-left-30 {
  margin-left: 30px;
}

.margin-left-35 {
  margin-left: 35px;
}

.margin-left-40 {
  margin-left: 40px;
}

.margin-left-45 {
  margin-left: 45px;
}

.margin-left-50 {
  margin-left: 50px;
}

.margin-left-55 {
  margin-left: 55px;
}

.margin-left-60 {
  margin-left: 60px;
}

.margin-left-65 {
  margin-left: 65px;
}

.margin-left-70 {
  margin-left: 70px;
}

.margin-left-75 {
  margin-left: 75px;
}

.margin-left-80 {
  margin-left: 80px;
}

.margin-left-85 {
  margin-left: 85px;
}

.margin-left-90 {
  margin-left: 90px;
}

.margin-left-95 {
  margin-left: 95px;
}

.margin-left-100 {
  margin-left: 100px;
}

.margin-left-105 {
  margin-left: 105px;
}

.margin-left-110 {
  margin-left: 110px;
}

.margin-left-115 {
  margin-left: 115px;
}

.margin-left-120 {
  margin-left: 120px;
}

.margin-left-125 {
  margin-left: 125px;
}

.margin-left-130 {
  margin-left: 130px;
}

.margin-left-135 {
  margin-left: 135px;
}

.margin-left-140 {
  margin-left: 140px;
}

.margin-left-145 {
  margin-left: 145px;
}

.margin-left-150 {
  margin-left: 150px;
}

.margin-left-155 {
  margin-left: 155px;
}

.margin-left-160 {
  margin-left: 160px;
}

.margin-left-165 {
  margin-left: 165px;
}

.margin-left-170 {
  margin-left: 170px;
}

.margin-left-175 {
  margin-left: 175px;
}

.margin-left-180 {
  margin-left: 180px;
}

.margin-left-185 {
  margin-left: 185px;
}

.margin-left-190 {
  margin-left: 190px;
}

.margin-left-195 {
  margin-left: 195px;
}

.margin-left-200 {
  margin-left: 200px;
}

.margin-bottom-0 {
  margin-bottom: 0px;
}

.margin-bottom-5 {
  margin-bottom: 5px;
}

.margin-bottom-10, .questions .modal .form-group {
  margin-bottom: 10px;
}

.margin-bottom-15 {
  margin-bottom: 15px;
}

.margin-bottom-20, #company-stage .form-group {
  margin-bottom: 20px;
}

.margin-bottom-25 {
  margin-bottom: 25px;
}

.margin-bottom-30 {
  margin-bottom: 30px;
}

.margin-bottom-35 {
  margin-bottom: 35px;
}

.margin-bottom-40, .questions .form-group {
  margin-bottom: 40px;
}

.margin-bottom-45 {
  margin-bottom: 45px;
}

.margin-bottom-50 {
  margin-bottom: 50px;
}

.margin-bottom-55 {
  margin-bottom: 55px;
}

.margin-bottom-60 {
  margin-bottom: 60px;
}

.margin-bottom-65 {
  margin-bottom: 65px;
}

.margin-bottom-70 {
  margin-bottom: 70px;
}

.margin-bottom-75 {
  margin-bottom: 75px;
}

.margin-bottom-80 {
  margin-bottom: 80px;
}

.margin-bottom-85 {
  margin-bottom: 85px;
}

.margin-bottom-90 {
  margin-bottom: 90px;
}

.margin-bottom-95 {
  margin-bottom: 95px;
}

.margin-bottom-100 {
  margin-bottom: 100px;
}

.margin-bottom-105 {
  margin-bottom: 105px;
}

.margin-bottom-110 {
  margin-bottom: 110px;
}

.margin-bottom-115 {
  margin-bottom: 115px;
}

.margin-bottom-120 {
  margin-bottom: 120px;
}

.margin-bottom-125 {
  margin-bottom: 125px;
}

.margin-bottom-130 {
  margin-bottom: 130px;
}

.margin-bottom-135 {
  margin-bottom: 135px;
}

.margin-bottom-140 {
  margin-bottom: 140px;
}

.margin-bottom-145 {
  margin-bottom: 145px;
}

.margin-bottom-150 {
  margin-bottom: 150px;
}

.margin-bottom-155 {
  margin-bottom: 155px;
}

.margin-bottom-160 {
  margin-bottom: 160px;
}

.margin-bottom-165 {
  margin-bottom: 165px;
}

.margin-bottom-170 {
  margin-bottom: 170px;
}

.margin-bottom-175 {
  margin-bottom: 175px;
}

.margin-bottom-180 {
  margin-bottom: 180px;
}

.margin-bottom-185 {
  margin-bottom: 185px;
}

.margin-bottom-190 {
  margin-bottom: 190px;
}

.margin-bottom-195 {
  margin-bottom: 195px;
}

.margin-bottom-200 {
  margin-bottom: 200px;
}

.margin-right-0 {
  margin-right: 0px;
}

.margin-right-5 {
  margin-right: 5px;
}

.margin-right-10 {
  margin-right: 10px;
}

.margin-right-15 {
  margin-right: 15px;
}

.margin-right-20 {
  margin-right: 20px;
}

.margin-right-25 {
  margin-right: 25px;
}

.margin-right-30 {
  margin-right: 30px;
}

.margin-right-35 {
  margin-right: 35px;
}

.margin-right-40 {
  margin-right: 40px;
}

.margin-right-45 {
  margin-right: 45px;
}

.margin-right-50 {
  margin-right: 50px;
}

.margin-right-55 {
  margin-right: 55px;
}

.margin-right-60 {
  margin-right: 60px;
}

.margin-right-65 {
  margin-right: 65px;
}

.margin-right-70 {
  margin-right: 70px;
}

.margin-right-75 {
  margin-right: 75px;
}

.margin-right-80 {
  margin-right: 80px;
}

.margin-right-85 {
  margin-right: 85px;
}

.margin-right-90 {
  margin-right: 90px;
}

.margin-right-95 {
  margin-right: 95px;
}

.margin-right-100 {
  margin-right: 100px;
}

.margin-right-105 {
  margin-right: 105px;
}

.margin-right-110 {
  margin-right: 110px;
}

.margin-right-115 {
  margin-right: 115px;
}

.margin-right-120 {
  margin-right: 120px;
}

.margin-right-125 {
  margin-right: 125px;
}

.margin-right-130 {
  margin-right: 130px;
}

.margin-right-135 {
  margin-right: 135px;
}

.margin-right-140 {
  margin-right: 140px;
}

.margin-right-145 {
  margin-right: 145px;
}

.margin-right-150 {
  margin-right: 150px;
}

.margin-right-155 {
  margin-right: 155px;
}

.margin-right-160 {
  margin-right: 160px;
}

.margin-right-165 {
  margin-right: 165px;
}

.margin-right-170 {
  margin-right: 170px;
}

.margin-right-175 {
  margin-right: 175px;
}

.margin-right-180 {
  margin-right: 180px;
}

.margin-right-185 {
  margin-right: 185px;
}

.margin-right-190 {
  margin-right: 190px;
}

.margin-right-195 {
  margin-right: 195px;
}

.margin-right-200 {
  margin-right: 200px;
}

.questions {
  font-size: 16pt;
  color: #1f4142;
}

.questions input[type=number],
.questions input[type=date],
.questions input[type=text] {
  font-size: 14pt;
  padding: 25px 10px;
}

.questions select {
  font-size: 12pt;
}

.questions section {
  margin-bottom: 80px;
}

.questions section h3 {
  font-size: 24pt;
  color: #2b5b5e;
  margin-bottom: 20px;
}

.questions .help-block {
  font-size: 12pt;
}

.checkbox.vertical label {
  display: block;
}

#addTeamMember textarea {
  height: 200px;
}

.popover {
  max-width: 300px;
}

textarea {
  min-height: 200px;
}

.limited-textarea {
  position: relative;
}

.limited-textarea span {
  color: #999;
  font-size: 10pt;
  position: absolute;
  bottom: 5;
  right: 10;
}

.header .container img {
  width: 150px;
}

.landing .container {
  width: 100%;
  padding: 0;
  margin: 0;
}

p {
  font-size: 16;
}

h3 {
  font-weight: bold;
}

.hero-container {
  height: 50%;
}

.hero-image {
  background-image: url("/images/landing_dark.jpg");
  background-size: cover;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.hero-image .hero-form {
  text-align: center;
}

.hero-image .hero-form .hero-input {
  border: 1px solid #cc6734;
  background-color: beige;
  padding: 20px;
  margin: 10px;
  font-size: 16px;
  text-align: center;
}

.hero-image .hero-form .hero-button {
  background-color: #cc6734;
  border: 1px solid #cc6734;
  padding: 10px;
  font-size: 16px;
}

.hero-text {
  color: white;
  font-size: 32px;
  text-align: center;
  padding-left: 10%;
  padding-right: 10%;
}

.body-container {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
  align-content: center;
  margin: 40px 10% 30px 10%;
}

.body-container .intro {
  margin: 20px;
  font-weight: 'bold';
}

.body-input {
  border: 1px solid #cc6734;
  background-color: beige;
  padding: 20px;
  margin: 5px 10px;
  font-size: 16px;
  width: 100%;
}

.body-radio {
  padding: 20px;
  margin: 5px 10px;
  font-size: 16px;
  width: 100%;
}

.body-button {
  margin: 5px 10px;
  background-color: #cc6734;
  border: 1px solid #cc6734;
  padding: 10px;
  font-size: 16px;
  width: 120px;
  text-align: center;
}

.info-panels {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  align-content: center;
  margin-top: 40px;
}

.info-panels .screenshot {
  order: 2;
  width: 100%;
  padding: 20px;
}

.info-panels .screenshot .sample-chat {
  margin: 0 auto;
  width: 100%;
}

.info-panels .blurb {
  order: 1;
  width: 100%;
  padding: 0 5% 0 5%;
}

.info-panels .app-info {
  order: 3;
  width: 100%;
  padding: 0 5% 0 5%;
}

.info-panels .app-invite {
  order: 4;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 20px;
}

.info-panels .app-invite .mobile-overlay {
  margin: 0 auto;
  width: 30%;
}

.info-panels .app-invite .group {
  padding: 20px;
  width: 70%;
  margin: 0 auto;
}

.info-panels .app-invite .group .app-store {
  width: 40%;
  margin: 0 5px;
}

.footer {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
  background-color: #343434;
  padding-top: 40px;
  padding-bottom: 50px;
}

.footer h3 {
  color: #cc6734;
}

.footer p {
  color: white;
  margin: 5px 10px 0 0;
}

.footer a {
  color: white;
  display: block;
  margin: 5px 10px 0 0;
}

.footer .footer-section-one {
  width: 100%;
  text-align: center;
}

.footer .footer-section-two {
  width: 100%;
  text-align: center;
}

.footer .footer-section-three {
  width: 100%;
  text-align: center;
}

@media (min-width: 800px) {
  .header {
    padding-left: 50px;
  }
  .header .container img {
    width: 300px;
    margin-left: 10%;
  }
  .hero-image .hero-form .hero-input {
    padding: 24px;
    margin: 10px;
    font-size: 20px;
  }
  .hero-image .hero-form .hero-button {
    padding: 10px;
    margin: 10px;
    font-size: 20px;
  }
  p {
    font-size: 21;
  }
  .hero-text {
    font-size: 50px;
  }
  .info-panels .screenshot {
    order: 1;
    width: 50%;
    padding: 20px;
  }
  .info-panels .screenshot .sample-chat {
    margin: 0 auto;
    width: 100%;
  }
  .info-panels .blurb {
    order: 2;
    width: 50%;
    padding: 20px;
  }
  .info-panels .app-info {
    order: 3;
    width: 50%;
    padding: 0 5% 0 5%;
  }
  .info-panels .app-invite {
    order: 4;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    width: 50%;
    padding: 20px;
  }
  .info-panels .app-invite .mobile-overlay {
    margin: 0 auto;
    width: 30%;
  }
  .info-panels .app-invite .group {
    padding: 20px;
    width: 70%;
  }
  .footer {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: flex-start;
    background-color: #343434;
    height: 250px;
  }
  .footer h3 {
    color: #cc6734;
  }
  .footer p {
    color: white;
    margin: 5px 10px 0 0;
  }
  .footer a {
    color: white;
    display: block;
    margin: 5px 10px 0 0;
  }
  .footer .footer-section-one {
    width: 31%;
    text-align: left;
  }
  .footer .footer-section-two {
    width: 31%;
    text-align: left;
  }
  .footer .footer-section-three {
    width: 31%;
    text-align: left;
  }
}

.ignored {
  opacity: 0.5;
}

.welcome input {
  font-size: 26pt;
  padding: 10px;
  width: 20em;
  text-align: center;
}

.admin-container {
  width: 80%;
  margin: 0 auto;
}

.logout-link {
  margin-right: 3%;
}

.chat {
  background: lightgrey;
  color: black;
  font-size: 14px;
  padding: 5px 15px;
  border-radius: 20px;
  margin-bottom: 10px;
  max-width: 225px;
}

.pull-right .chat {
  background: #cc6734;
  color: white;
  border: 2px solid #cc6734;
}

.wait {
  background: lightgrey url(/images/loading-dots-sm.gif) no-repeat;
  background-size: contain;
  width: 72px;
  height: 23px;
}

.conversation {
  width: 90%;
  margin: 0 auto;
  margin-bottom: 200px;
  margin-top: 30px;
}

.chatForm {
  max-width: 100%;
  margin-bottom: 20px;
}

.chatForm input {
  font-size: 14px;
  padding: 5px 10px;
  border-radius: 10px;
  border: 2px solid #ddd;
}

.options {
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
  max-width: 100%;
  width: 100%;
}

.option {
  border: 2px solid #cc6734;
  background-color: white;
  color: #cc6734;
  padding: 5px 10px;
  margin: 0px 5px 10px 0px;
  border-radius: 10px;
  max-width: 130px;
  text-align: center;
  cursor: pointer;
  font-size: 14px;
}

.incDec {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 100%;
  width: 100%;
}

.previous {
  width: auto;
  margin-right: 19%;
  margin-left: 19%;
  float: center;
  border-radius: 10px;
  margin-top: 20px;
}

.previous p {
  margin: 0;
}

.previous .text {
  padding: 10px;
  color: rgba(52, 52, 52, 0.4);
  text-decoration: underline;
  text-decoration-color: rgba(204, 102, 51, 0.4);
  text-align: center;
}

.previous .text:hover {
  text-decoration-color: white;
  color: rgba(52, 52, 52, 0.8);
}

.previous:hover {
  background-color: rgba(204, 102, 51, 0.8);
  cursor: pointer;
}

.intervals {
  flex-grow: 1;
}

.intervals .set {
  margin-top: 15px;
  margin-bottom: 15px;
}

.intervals .option {
  border: 2px solid green;
  background-color: white;
  color: green;
  padding: 5px 10px;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
  font-size: 14px;
}

.intervals .option:hover {
  background-color: green;
  color: white;
}

.intervals .minusoption {
  border: 2px solid red;
  background-color: white;
  color: red;
  padding: 5px 10px;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
  font-size: 14px;
}

.intervals .minusoption:hover {
  background-color: red;
  color: white;
}

.formattedTotal {
  flex-grow: 1;
  justify-content: center;
  align-self: center;
}

.formattedTotal .totalText {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 10px;
}

.pickedOption {
  border: 2px solid #cc6734;
  background-color: #cc6734;
  color: white;
}

.instructions {
  font-size: 14px;
  color: black;
  margin-bottom: 10px;
  margin-left: 20px;
}

.button {
  border: 2px solid #cc6734;
  background-color: #cc6734;
  color: white;
  padding: 10px 20px;
  border-radius: 10px;
  margin-bottom: 20px;
  margin-right: 10px;
  max-width: 300px;
  text-align: center;
  cursor: pointer;
  font-size: 14px;
}

.fade-transition {
  transition: all 0.5s ease;
}

.fade-enter, .fade-leave {
  opacity: 0;
}

.fade-leave {
  transition: all 0s ease;
}

@media (min-width: 400px) {
  .chat {
    font-size: 16px;
    padding: 10px 20px;
    max-width: 300px;
  }
  .pull-right .chat {
    border: 2px solid #cc6734;
  }
  .wait {
    width: 79px;
    height: 28px;
  }
  .conversation {
    width: 85%;
    margin-top: 40px;
  }
  .chatForm input {
    font-size: 16px;
    padding: 10px 15px;
  }
  .option {
    border: 2px solid #cc6734;
    padding: 5px 10px;
    margin: 0px 5px 10px 0px;
    max-width: 180px;
    font-size: 16px;
  }
  .button {
    max-width: 200px;
    font-size: 16px;
  }
  .intervals .set {
    margin-top: 20px;
    margin-bottom: 30px;
  }
  .intervals .option {
    padding: 10px 15px;
    font-size: 16px;
  }
  .intervals .minusoption {
    padding: 10px 15px;
    font-size: 16px;
  }
  .formattedTotal .totalText {
    font-size: 16px;
  }
  .pickedOption {
    padding: 5px 10px;
    margin: 0px 5px 10px 0px;
    max-width: 180px;
    font-size: 16px;
  }
  .instructions {
    font-size: 16px;
  }
}

@media (min-width: 600px) {
  .chat {
    font-size: 18px;
    padding: 10px 20px;
    max-width: 400px;
  }
  .pull-right .chat {
    border: 2px solid #cc6734;
  }
  .wait {
    width: 96px;
    height: 32px;
  }
  .conversation {
    width: 80%;
    margin-bottom: 200px;
    margin-top: 50px;
  }
  .chatForm {
    max-width: 100%;
    margin-bottom: 20px;
  }
  .chatForm input {
    font-size: 18px;
    padding: 10px 15px;
    border-radius: 10px;
    border: 2px solid #ddd;
  }
  .options {
    max-width: 100%;
    width: 100%;
  }
  .option {
    border: 2px solid #cc6734;
    padding: 10px 20px;
    margin: 0px 5px 10px 0px;
    max-width: 230px;
    font-size: 18px;
  }
  .option:hover {
    background-color: #cc6734;
    color: white;
  }
  .incDec {
    max-width: 100%;
    width: 100%;
  }
  .intervals {
    flex-grow: 1;
  }
  .intervals .set {
    margin-top: 20px;
    margin-bottom: 35px;
  }
  .intervals .option {
    border: 2px solid green;
    padding: 10px 20px;
    font-size: 18px;
  }
  .intervals .minusoption {
    border: 2px solid red;
    padding: 10px 20px;
    border-radius: 10px;
    font-size: 18px;
  }
  .intervals .minusoption:hover {
    background-color: red;
    color: white;
  }
  .formattedTotal {
    flex-grow: 1;
  }
  .formattedTotal .totalText {
    font-size: 18px;
    margin-bottom: 10px;
  }
  .pickedOption {
    padding: 10px 20px;
    margin: 0px 5px 10px 0px;
    max-width: 230px;
    font-size: 18px;
  }
  .pickedOption:hover {
    background-color: #d6865d;
  }
  .instructions {
    font-size: 18px;
    margin-bottom: 20px;
    margin-left: 30px;
  }
  .button {
    max-width: 200px;
    font-size: 18px;
  }
}

@media (min-width: 768px) {
  .chat {
    font-size: 28px;
    padding: 10px 30px;
    border-radius: 40px;
    margin-bottom: 20px;
    max-width: 500px;
  }
  .pull-right .chat {
    border: 2px solid #cc6734;
  }
  .wait {
    width: 125px;
    height: 41px;
  }
  .conversation {
    width: 80%;
    margin-bottom: 250px;
    margin-top: 120px;
  }
  .chatForm input {
    font-size: 24px;
    padding: 10px;
  }
  .option {
    border: 2px solid #cc6734;
    padding: 10px 20px;
    margin: 0px 5px 10px 0px;
    max-width: 240px;
    font-size: 20px;
  }
  .incDec {
    max-width: 100%;
    width: 100%;
  }
  .intervals .set {
    margin-top: 30px;
    margin-bottom: 40px;
  }
  .intervals .option {
    padding: 10px 20px;
    font-size: 20px;
  }
  .intervals .minusoption {
    padding: 10px 20px;
    font-size: 20px;
  }
  .formattedTotal .totalText {
    font-size: 26px;
  }
  .pickedOption {
    padding: 10px 20px;
    margin: 0px 5px 10px 0px;
    max-width: 240px;
    font-size: 20px;
  }
  .instructions {
    font-size: 20px;
  }
  .button {
    max-width: 300px;
    font-size: 20px;
  }
}

@media (min-width: 1200px) {
  .chat {
    max-width: 600px;
  }
  .conversation {
    width: 65%;
  }
}

body {
  background: #fff;
  font-family: 'Open Sans';
  color: #1f4142;
}

h1 {
  margin: 0;
  padding: 0;
  width: 100%;
  display: table;
  font-weight: 800;
  font-size: 40pt;
  text-transform: uppercase;
}

.header {
  background: #ffffff;
  padding: 10px 0;
}

hr {
  height: 1px;
  background-color: #bbb;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 95%;
}

form.fancy label {
  font-size: 20pt;
}

form.fancy input[type=text] {
  font-size: 16pt;
  border-radius: 10px;
  padding: 20px 10px;
  border-color: #ddd;
  color: #339;
}

.callout {
  padding: 20px 30px;
  background: #bacdce;
  color: #1f4142;
  border-radius: 10px;
}

.logout-link form {
  display: inline-block;
}

.logout-link .btn-link {
  padding-left: 0;
  padding-right: 0;
  display: inline-block;
}
