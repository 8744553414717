$blue: #1a82bf;
$orange: #cc6734;
$primaryColor: $orange;

.logout-link {
    margin-right: 3%;
}

.chat {
    background: lightgrey;
    color: black;
    font-size: 14px;
    padding: 5px 15px;
    border-radius: 20px;
    margin-bottom: 10px;
    max-width: 225px;

    .pull-right & {
        background: $primaryColor;
        color: white;
        border: 2px solid $primaryColor;
    }
}

.wait {
    background: lightgrey  url(/images/loading-dots-sm.gif) no-repeat;
    background-size: contain;
    width: 72px;
    height: 23px;
}

.conversation {
    width: 90%;
    margin: 0 auto;
    margin-bottom: 200px;
    margin-top: 30px;
}

.chatForm {
    max-width: 100%;
    margin-bottom: 20px;

    input {
        font-size: 14px;
        padding: 5px 10px;
        border-radius: 10px;
        border: 2px solid #ddd;
    }
}

.options {
    display: flex;
    justify-content: left;
    flex-wrap: wrap;
    max-width: 100%;
    width: 100%;
}

.option {
    border: 2px solid $primaryColor;
    background-color: white;
    color: $primaryColor;
    padding: 5px 10px;
    margin: 0px 5px 10px 0px;
    border-radius: 10px;
    max-width: 130px;
    text-align: center;
    cursor: pointer;
    font-size: 14px;
}

.incDec {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 100%;
    width: 100%;
}

.previous {
    p {
        margin: 0;
    }
    width: auto;
    margin-right: 19%;
    margin-left: 19%;
    float: center;
    border-radius: 10px;
    margin-top: 20px;
    .text {
        padding: 10px;
        color: rgba(52, 52, 52, 0.4);
        text-decoration: underline;
        text-decoration-color: rgba(204, 102, 51, 0.4);
        text-align: center;
        &:hover {
            text-decoration-color: white;
            color: rgba(52, 52, 52, 0.8);
        }
    }
    &:hover {
        background-color: rgba(204, 102, 51, 0.8);
        cursor: pointer;
    }
}



.intervals {
    flex-grow: 1;
    .set {
        margin-top: 15px;
        margin-bottom: 15px;
    }
    .option {
        border: 2px solid green;
        background-color: white;
        color: green;
        padding: 5px 10px;
        border-radius: 10px;
        text-align: center;
        cursor: pointer;
        font-size: 14px;

        &:hover {
            background-color: green;
            color: white;
        }
    }
    .minusoption {
        border: 2px solid red;
        background-color: white;
        color: red;
        padding: 5px 10px;
        border-radius: 10px;
        text-align: center;
        cursor: pointer;
        font-size: 14px;

        &:hover {
            background-color: red;
            color: white;
        }
    }
}

.formattedTotal {
    flex-grow: 1;
    justify-content: center;
    align-self: center;
    .totalText {
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 10px;
    }
}

.pickedOption {
    border: 2px solid $primaryColor;
    background-color: $primaryColor;
    color: white;
}

.instructions {
    font-size: 14px;
    color: black;
    margin-bottom: 10px;
    margin-left: 20px;
}

.button {
    border: 2px solid $primaryColor;
    background-color: $primaryColor;
    color: white;
    padding: 10px 20px;
    border-radius: 10px;
    margin-bottom: 20px;
    margin-right: 10px;
    max-width: 300px;
    text-align: center;
    cursor: pointer;
    font-size: 14px;
}

.fade-transition {
  transition: all 0.5s ease;
}

.fade-enter, .fade-leave {
  opacity: 0;
}

.fade-leave {
    transition: all 0s ease;
}

@media (min-width: 400px) {
    .chat {
        font-size: 16px;
        padding: 10px 20px;
        max-width: 300px;
        .pull-right & {
            border: 2px solid $primaryColor;
        }
    }

    .wait {
        width: 79px;
        height: 28px;
    }

    .conversation {
        width: 85%;
        margin-top: 40px;
    }

    .chatForm {
        input {
            font-size: 16px;
            padding: 10px 15px;
        }
    }

    .option {
        border: 2px solid $primaryColor;
        padding: 5px 10px;
        margin: 0px 5px 10px 0px;
        max-width: 180px;
        font-size: 16px;
    }

    .button {
        max-width: 200px;
        font-size: 16px;
    }

    .intervals {
        .set {
            margin-top: 20px;
            margin-bottom: 30px;
        }
        .option {
            padding: 10px 15px;
            font-size: 16px;
        }
        .minusoption {
            padding: 10px 15px;
            font-size: 16px;
        }
    }

    .formattedTotal {
        .totalText {
            font-size: 16px;
        }
    }

    .pickedOption {
        padding: 5px 10px;
        margin: 0px 5px 10px 0px;
        max-width: 180px;
        font-size: 16px;
    }

    .instructions {
        font-size: 16px;
    }
}

@media (min-width: 600px) {
    .chat {
        font-size: 18px;
        padding: 10px 20px;
        max-width: 400px;
        .pull-right & {
            border: 2px solid $primaryColor;
        }
    }

    .wait {
        width: 96px;
        height: 32px;
    }

    .conversation {
        width: 80%;
        margin-bottom: 200px;
        margin-top: 50px;
    }

    .chatForm {
        max-width: 100%;
        margin-bottom: 20px;

        input {
            font-size: 18px;
            padding: 10px 15px;
            border-radius: 10px;
            border: 2px solid #ddd;
        }
    }

    .options {
        max-width: 100%;
        width: 100%;
    }

    .option {
        border: 2px solid $primaryColor;
        padding: 10px 20px;
        margin: 0px 5px 10px 0px;
        max-width: 230px;
        font-size: 18px;

        &:hover {
            background-color: $primaryColor;
            color: white;
        }
    }

    .incDec {
        max-width: 100%;
        width: 100%;
    }

    .intervals {
        flex-grow: 1;
        .set {
            margin-top: 20px;
            margin-bottom: 35px;
        }
        .option {
            border: 2px solid green;
            padding: 10px 20px;
            font-size: 18px;
        }
        .minusoption {
            border: 2px solid red;
            padding: 10px 20px;
            border-radius: 10px;
            font-size: 18px;

            &:hover {
                background-color: red;
                color: white;
            }
        }
    }

    .formattedTotal {
        flex-grow: 1;
        .totalText {
            font-size: 18px;
            margin-bottom: 10px;
        }
    }

    .pickedOption {
        padding: 10px 20px;
        margin: 0px 5px 10px 0px;
        max-width: 230px;
        font-size: 18px;

        &:hover {
            background-color: lighten($primaryColor, 10%)
        }
    }

    .instructions {
        font-size: 18px;
        margin-bottom: 20px;
        margin-left: 30px;
    }

    .button {
        max-width: 200px;
        font-size: 18px;
    }
}

@media (min-width: 768px) {
    .chat {
        font-size: 28px;
        padding: 10px 30px;
        border-radius: 40px;
        margin-bottom: 20px;
        max-width: 500px;

        .pull-right & {
            border: 2px solid $primaryColor;
        }
    }

    .wait {
        width: 125px;
        height: 41px;
    }

    .conversation {
        width: 80%;
        margin-bottom: 250px;
        margin-top: 120px;
    }

    .chatForm {
        input {
            font-size: 24px;
            padding: 10px;
        }
    }

    .option {
        border: 2px solid $primaryColor;
        padding: 10px 20px;
        margin: 0px 5px 10px 0px;
        max-width: 240px;
        font-size: 20px;
    }

    .incDec {
        max-width: 100%;
        width: 100%;
    }

    .intervals {
        .set {
            margin-top: 30px;
            margin-bottom: 40px;
        }
        .option {
            padding: 10px 20px;
            font-size: 20px;
        }
        .minusoption {
            padding: 10px 20px;
            font-size: 20px;
        }
    }

    .formattedTotal {
        .totalText {
            font-size: 26px;
        }
    }

    .pickedOption {
        padding: 10px 20px;
        margin: 0px 5px 10px 0px;
        max-width: 240px;
        font-size: 20px;
    }

    .instructions {
        font-size: 20px;
    }

    .button {
        max-width: 300px;
        font-size: 20px;
    }
}

@media (min-width: 1200px) {
    .chat {
        max-width: 600px;
    }

    .conversation {
        width: 65%;
    }
}
