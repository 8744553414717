// @import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

@import "variables";
@import "utility";
@import "forms";
@import "landing";
@import "pages/all";
@import "pages/admin";
@import "chat/main";

body {
    background: #fff;
    font-family: 'Open Sans';
    color: $primaryDark;
}

h1 {
    margin: 0;
    padding: 0;
    width: 100%;
    display: table;
    font-weight: 800;
    font-size: 40pt;
    text-transform: uppercase;;
    a {
    }
}

.header {
    background: #ffffff;
    padding:10px 0;
}

hr {
    height: 1px;
    background-color:#bbb;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 95%;
}

form.fancy {
    label {
        font-size: 20pt;
    }
    input[type=text] {
        font-size: 16pt;
        border-radius: 10px;
        padding: 20px 10px;
        border-color: #ddd;
        color: #339;
    }
}

.callout {
    padding: 20px 30px;
    background: $primaryLightDesat;
    color: $primaryDark;
    border-radius: 10px;
}

.logout-link {
    form {
        display: inline-block;
    }
    .btn-link {
        padding-left: 0;
        padding-right: 0;

        display: inline-block;
    }
}
